<template>
  <div>
    <heads :routeUrl="url"></heads>
    <div class="w">
      <!-- 面包屑 -->
      <div class="breadcrumb d-flex align-center">
        <div class="breadcrumb-title d-flex align-center cursor">
          <span></span>
          课程中心
          <span></span>
        </div>
        <ul class="breadcrumb-list d-flex align-center">
          <li class="breadcrumb-item cursor">{{ list.name }}</li>
          <!-- <li class="breadcrumb-item">></li> -->
          <!-- <li class="breadcrumb-item cursor">第一节教育</li> -->
        </ul>
      </div>
      <div class="top d-flex justify-between">
        <div class="top-img">
          <img :src="list.imgPath" alt="" />
        </div>
        <div class="top-r">
          <div class="title d-flex justify-between">
            <div class="title-name">
              {{ list.name }}
            </div>
            <!-- <div class="title-zan cursor">
              <img src="../../assets/img/collect.png" alt="" />
            </div> -->
          </div>
          <div class="teacher d-flex align-end justify-between">
            <!-- <div class="teacher-name">主讲老师：{{item}}</div> -->
            <div class="pic">￥{{ list.price }}</div>
          </div>
          <div class="top-b d-flex justify-between">
            <div class="left">
              <p class="people">购买人数：{{ list.salsCount }}人</p>
              <p class="time">
                课程有限期：{{ list.lookEndTime }}
                <span
                  >标签 :<span
                    v-for="(item, index) in list.lableName"
                    :key="index"
                    v-show="index < 2"
                    >{{ item }}</span
                  ></span
                >
              </p>
            </div>
            <div
              v-show="list.buyStatus == 0"
              class="btn d-flex align-center justify-center cursor"
              @click="goOrder(list.id)"
            >
              立即购买
            </div>
          </div>
        </div>
      </div>
      <div class="content d-flex justify-between">
        <div class="left">
          <ul class="tab d-flex align-center justify-center">
            <li
              class="tab-item cursor"
              @click="selectTab(0)"
              :class="{ tab_active: tabIndex == 0 }"
            >
              课程详情
            </li>
            <li
              class="tab-item cursor"
              @click="selectTab(1)"
              :class="{ tab_active: tabIndex == 1 }"
            >
              录播目录
            </li>
            <li
              class="tab-item cursor"
              @click="selectTab(2)"
              :class="{ tab_active: tabIndex == 2 }"
            >
              直播目录
            </li>
            <li
              class="tab-item cursor"
              @click="selectTab(3)"
              :class="{ tab_active: tabIndex == 3 }"
            >
              资料下载
            </li>
            <!-- <li
              class="tab-item cursor"
              @click="selectTab(3)"
              :class="{ tab_active: tabIndex == 3 }"
            >
              课程评价
            </li> -->
          </ul>
          <!-- 课程详情 -->
          <div class="detail" v-show="tabIndex == 0">
            <img v-if="list.detailPath != ''" :src="list.detailPath" alt="" />
            <div
              v-if="list.detailPath == ''"
              class="kong d-flex align-center flex-column justify-center"
            >
              <img class="kong-img" src="../../assets/img/kong.png" alt="" />
              <div class="kong-txt">暂无详情</div>
            </div>
          </div>
          <!-- 录播目录 -->
          <div class="menu-box">
            <ul
              v-show="tabIndex == 1 && menulist"
              v-for="(itemes, index) in menulist"
              :key="index"
            >
              <li>
                <div
                  class="first d-flex align-center cursor"
                  @click.stop="toggleChildren(itemes)"
                >
                  <img
                    class="first-img"
                    :class="{ transform: itemes.expanded }"
                    src="../../assets/img/fold_big.png"
                    alt=""
                  />
                  <div class="firit-name">{{ itemes.name }}</div>
                  <div class="percent">
                    共{{ itemes.lessonList.length}}节
                  </div>
                </div>
                <el-collapse-transition>
                  <ul v-show="itemes.expanded">
                    <li
                      v-for="(childes, ids) in itemes.lessonList"
                      :key="ids"
                    >
                      <div class="second d-flex align-center cursor">
                        <div class="second-name">{{ childes.name }}</div>
                        <!-- <div
                          class="second-tag d-flex align-center justify-center"
                        >
                          限时免费
                        </div> -->
                        <!-- <div
                          class="second-label d-flex align-center justify-center"
                        >
                          付费项目
                        </div> -->
                        <div
                          v-show="list.buyStatus == 1"
                          class="btn d-flex align-center justify-center cursor"
                          @click="goRecording(childes)"
                        >
                          听课学习
                        </div>
                        <div class="btnimg" v-show="list.buyStatus == 0">
                          <img src="../../assets/img/nopic.png" alt="" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </el-collapse-transition>
              </li>
            </ul>
            <div
              v-if="!menulist && tabIndex == 1"
              class="kong d-flex align-center flex-column justify-center"
            >
              <img class="kong-img" src="../../assets/img/kong.png" alt="" />
              <div class="kong-txt">暂无内容</div>
            </div>
          </div>
          <!-- 直播目录 -->
          <div class="menu-box">
            <ul
              v-show="tabIndex == 2 && menulists"
              v-for="item in menulists"
              :key="item.id"
            >
              <li>
                <div
                  class="first d-flex align-center cursor"
                  @click.stop="toggleChildren(item)"
                >
                  <img
                    class="first-img"
                    :class="{ transform: item.expanded }"
                    src="../../assets/img/fold_big.png"
                    alt=""
                  />
                  <div class="firit-name">{{ item.name }}</div>
                  <div class="percent">共{{ item.lessonList.length }}节</div>
                </div>
                <el-collapse-transition>
                  <ul v-show="item.expanded">
                    <li v-for="child in item.lessonList" :key="child.id">
                      <div class="second d-flex align-center cursor">
                        <div class="second-name">{{ child.name }}</div>
                        <!-- <div
                          class="second-tag d-flex align-center justify-center"
                        >
                          限时免费
                        </div> -->
                        <!-- <div
                          class="second-label d-flex align-center justify-center"
                        >
                          付费项目
                        </div> -->
                        <!-- <div
                          class="btn d-flex align-center justify-center cursor"
                        >
                          去做题
                        </div> -->
                        <div class="btnimg" v-show="list.buyStatus == 0">
                          <img src="../../assets/img/nopic.png" alt="" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </el-collapse-transition>
              </li>
            </ul>
            <div
              v-if="menulists == '' && tabIndex == 2"
              class="kong d-flex align-center flex-column justify-center"
            >
              <img class="kong-img" src="../../assets/img/kong.png" alt="" />
              <div class="kong-txt">暂无内容</div>
            </div>
          </div>
          <!-- 资料下载 -->
          <div v-show="tabIndex == 3">
            <ul
              class="download d-flex flex-wrap justify-between"
              v-if="documents != ''"
            >
              <li
                class="download-item d-flex align-center justify-between"
                v-for="(items, idx) in documents"
                :key="idx"
              >
                <div class="download-top d-flex flex-column justify-between">
                  <p>{{ items.name }}</p>
                  <!-- <p>15.86MB</p> -->
                </div>
                <div class="download-btn" v-if="list.buyStatus == 0">
                  <img src="../../assets/img/nopic.png" alt="" />
                </div>
                <a
                  class="download-btn"
                  :href="items.fileurl"
                  v-if="list.buyStatus == 1"
                >
                  <img src="../../assets/img/dow_active.png" alt="" />
                </a>
              </li>
            </ul>
            <div
              v-if="documents == '' && tabIndex == 3"
              class="kong d-flex align-center flex-column justify-center"
            >
              <img class="kong-img" src="../../assets/img/kong.png" alt="" />
              <div class="kong-txt">暂无内容</div>
            </div>
            <v-pagination
              v-show="total > 12"
              :total="total"
              :current-page="current"
              :display="display"
              @pagechange="pagechange"
            ></v-pagination>
          </div>
        </div>
        <div class="right">
          <div
            class="item cursor"
            v-for="(item, index) in recommend"
            :key="index"
            @click="goDetail(item.id)"
          >
            <img class="item-img" :src="item.icon" />
            <div class="item-conter d-flex justify-between flex-column">
              <p class="item-title">{{ item.name }}</p>
              <div class="item-bottom d-flex justify-between align-center">
                <div class="item-pic">
                  <span>￥</span>
                  {{ item.price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import pagination from "@/components/pagination";
export default {
  data() {
    return {
      total: 0, // 记录总条数
      display: 12, // 每页显示条数
      current: 1, // 当前的页数
      url: "course",
      tabIndex: 0,
      menulist: [],
      menulists: [],
      id: "",
      type: "",
      recommend: "", // 推荐
      documents: "", // 资料
      list: "", // 目录
    };
  },
  components: {
    "v-pagination": pagination
  },
  watch: {
    $route: function (newVal, oldVal) {
      console.log(oldVal); //oldVa 上一次url
      console.log(newVal); //newVal 这一次的url
      console.log(newVal != oldVal);
      if (newVal != oldVal) {
        location.reload();
      }
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.getCatalogue();
    // this.getDocuments();
    this.getDetails();
    this.getLive();
    // this.getImage();
  },
  methods: {
    goRecording(item) {
      console.log(item);
      location.href = "./html/vod.html";
      let vodlive = {
        ownerid: item.videoId,
        authcode: item.token
      };
      window.localStorage.setItem("vodlive", JSON.stringify(vodlive));
      // this.$router.push("recording");
    },
    goOrder(id) {
      this.$router.push({
        name: `order`,
        query: {
          id: id
        }
      });
    },
    selectTab(index) {
      this.tabIndex = index;
    },
    toggleChildren(item) {
      console.log(item);
      item.expanded = !item.expanded;
    },
    pagechange(currentPage) {
      console.log(currentPage); //该参数就是当前点击的页码数
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
      this.current = currentPage;
      this.getlist();
      const currentY =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(currentY, 0);
    },
    //详情
    // packageDetail
    async getDetails(params) {
      let _this = this;
      let data = _this.id + '/' + _this.type
      const res = await this.$ajaxRequest('get', 'packageDetail', '', data)
      console.log(res.data);
      _this.list = res.data;
      _this.getPackagesLabels();
    },
    // getDetails() {
    //   let _this = this;
    //   _this.$api
    //     .isBuySourcePackages({
    //       sourcePackageId: _this.id
    //     })
    //     .then(res => {
    //       // console.log(res);
    //       _this.list = res.data;
    //       _this.getPackagesLabels();
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    // 详情图片
    // getImage() {
    //   let _this = this;
    //   _this.$api
    //     .sourcePackagesPicture({
    //       id: _this.id,
    //       type: _this.type
    //     })
    //     .then(res => {
    //       console.log(res);
    //       if (res.data) {
    //         _this.image = res.data[0].filePath;
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    // //录波目录
     async getCatalogue(params) {
      let _this = this;
      let data = _this.id
      const res = await this.$ajaxRequest('get', 'sourceCatalogue', '', data)
      console.log(res.data);
      // _this.recommend = res.data;
      let list = res.data;
      if (list) {
        for (let i in list) {
          _this.$set(list[i], "expanded", false);
          _this.$set(list[0], "expanded", true);
        }
      }
      console.log(list);
      _this.menulist = list;
    },
    // getCatalogue() {
    //   let _this = this;
    //   _this.$api
    //     .sourceCatalogue({
    //       sourcePackageId: _this.id
    //     })
    //     .then(res => {
    //       let list = res.data.sourceCourseDtoList;
    //       for (let i in list) {
    //         _this.$set(list[0], "expanded", true);
    //         _this.$set(list[i], "expanded", false);
    //       }
    //       _this.menulist = list;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    //直播目录
    async getLive(params) {
      let _this = this;
      let data = _this.id
      const res = await this.$ajaxRequest('get', 'catalogueLive', '', data)
      console.log(res.data);
      // _this.recommend = res.data;
      let list = res.data;
      if (list) {
        for (let i in list) {
          _this.$set(list[i], "expanded", false);
          _this.$set(list[0], "expanded", true);
        }
      }
      console.log(list);
      _this.menulists = list;
    },

    //资料下载

    // getDocuments() {
    //   let _this = this;
    //   _this.$api
    //     .documents({
    //       sourcePackageId: _this.id
    //     })
    //     .then(res => {
    //       // console.log("资料");
    //       // console.log(res);
    //       _this.documents = res.data;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    // //推荐
    async getPackagesLabels(params) {
      let _this = this;
      let data = _this.id
      const res = await this.$ajaxRequest('get', 'sourcePackagesLabels', '', data)
      console.log(res.data);
      _this.recommend = res.data;
    },
    // getPackagesLabels() {
    //   let _this = this;
    //   _this.$api
    //     .sourcePackagesLabels({
    //       labelIds: _this.list.labelIds,
    //       majorId: _this.list.major.id,
    //       sourcePackagesId: _this.id
    //     })
    //     .then(res => {
    //       // console.log(res);
    //       _this.recommend = res.data;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },
    goDetail(id) {
      this.$router.push({
        name: `courseDetail`,
        query: {
          id: id,
          type: '2'
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
.transform {
  transform: rotate(180deg);
}
.tab_active {
  font-size: 24px !important;
  color: #5f2eff !important;
  &::after {
    display: block !important;
  }
}
// 面包屑
.breadcrumb {
  margin-top: 29px;
  .breadcrumb-title {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    // padding-left: 15px;
    // box-sizing: border-box;
    // border-left: 4px solid #5f2eff;
    span:first-child {
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #5f2eff;
      border-radius: 2px;
      margin-right: 11px;
    }
    span:last-child {
      width: 1px;
      height: 10px;
      background: #cccccc;
      border-radius: 1px;
      margin-left: 12px;
    }
  }
  .breadcrumb-item {
    font-size: 14px;
    color: #888888;
    margin-left: 12px;
  }
}
.top {
  // width: 1200px;
  height: 262px;
  border-radius: 14px;
  margin-top: 20px;
  background: url(../../assets/img/course_top.png) center no-repeat;
  padding: 18px 42px 18px 18px;
  box-sizing: border-box;
  .top-img {
    width: 392px;
    height: 226px;
    border-radius: 10px;
    overflow: hidden;
  }
  .top-r {
    width: 712px;
    .title {
      .title-name {
        width: 438px;
        height: 56px;
        font-size: 24px;
        font-weight: bold;
        color: #444444;
        line-height: 32px;
      }
      .title-zan {
        width: 36px;
        height: 32px;
      }
    }
    .teacher {
      margin-top: 32px;
      .teacher-name {
        font-size: 22px;
        color: #666666;
      }
      .pic {
        font-size: 50px;
        font-weight: bold;
        color: #f53444;
      }
    }
    .top-b {
      margin-top: 20px;
      .left {
        .people {
          font-size: 16px;
          color: #5f2eff;
        }
        .time {
          font-size: 16px;
          color: #666666;
          span {
            font-size: 16px;
            color: #666666;
            margin-left: 21px;
          }
        }
      }
      .btn {
        width: 134px;
        height: 42px;
        background: linear-gradient(90deg, #ffba13 0%, #fb283a 100%);
        border-radius: 8px;
        font-size: 22px;
        color: #ffffff;
      }
    }
  }
}
.content {
  margin-top: 30px;
  .left {
    width: 840px;
    min-height: 600px;
    height: 100%;
    border-radius: 10px;
    background: #ffffff;
    padding: 50px 45px;
    box-sizing: border-box;
    .tab {
      margin-bottom: 50px;
      .tab-item {
        font-size: 24px;
        color: #666666;
        position: relative;
        &::after {
          content: "";
          width: 46px;
          height: 4px;
          background: #5f2eff;
          position: absolute;
          left: 50%;
          margin-left: -23px;
          bottom: -9px;
          display: none;
        }
        + .tab-item {
          margin-left: 70px;
        }
      }
    }
    //课程详情
    .detail {
      // background-color: pink;
    }
    // 客场目录\
    .menu-box {
      margin-top: 44px;
      .first {
        height: 58px;
        border-bottom: 1px solid #f6f9ff;
        padding-left: 30px;
        box-sizing: border-box;
        background: #f4f5fb;
        .first-img {
          width: 20px;
          height: 11px;
          margin-right: 10px;
          transition: 0.35s;
          -moz-transition: 0.35s;
          -webkit-transition: 0.35s;
          -o-transition: 0.35s;
        }
        .firit-name {
          font-size: 18px;
          color: #222222;
        }
        .percent {
          font-size: 18px;
          color: #5f2eff;
          margin-left: auto;
          margin-right: 32px;
        }
        .num {
          font-size: 16px;
          color: #999999;
          width: 100px;
          text-align: center;
          margin-right: 16px;
        }
        .btn {
          height: 32px;
          width: 80px;
          font-size: 16px;
          color: #ffffff;
          margin-right: 7px;
        }
      }
      .second {
        height: 86px;
        border-bottom: 1px solid #f6f9ff;
        box-sizing: border-box;
        padding-left: 62px;
        .second-img {
          width: 14px;
          height: 9px;
          margin-right: 10px;
          transition: 0.35s;
          -moz-transition: 0.35s;
          -webkit-transition: 0.35s;
          -o-transition: 0.35s;
        }
        .second-name {
          font-size: 16px;
          color: #666666;
        }
        .second-tag {
          width: 74px;
          height: 22px;
          background: #f2f0ff;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: #5f2eff;
          margin-left: 19px;
        }
        .second-label {
          width: 74px;
          height: 22px;
          background: #f1f1f1;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: #666666;
          margin-left: 19px;
          display: none;
        }
        .btn {
          font-size: 14px;
          color: #ffffff;
          width: 83px;
          height: 30px;
          background-image: linear-gradient(176deg, #406bff 0%, #5f2eff 100%),
            linear-gradient(#f36470, #f36470);
          background-blend-mode: normal, normal;
          border-radius: 15px;
          margin-left: auto;
        }
        .btnimg {
          width: 18px;
          height: 20px;
          margin-left: auto;
          margin-right: 44px;
        }
      }
    }

    //资料下载
    .download {
      margin-top: 44px;
      .download-item {
        width: 364px;
        height: 98px;
        background: #f4f5fb;
        border-radius: 10px;
        padding: 25px 28px;
        box-sizing: border-box;
        margin-bottom: 20px;
        .download-top {
          p {
            color: #5f2eff;
            font-size: 16px;
          }
          // :last-child {
          //   font-size: 12px;
          //   color: #999999;
          // }
        }
        .download-btn {
          width: 18px;
          height: 20px;
        }
      }
    }
  }
  .right {
    width: 330px;
    height: 100%;
    border-radius: 10px;
    .item {
      width: 330px;
      height: 270px;
      background: #ffffff;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 30px;
      .item-img {
        width: 330px !important;
        height: 190px;
        border-radius: 10px;
      }
      .item-conter {
        padding: 10px 12px 12px 12px;
        box-sizing: border-box;
      }
      .item-title {
        font-size: 16px;
        color: #444444;
      }
      .item-bottom {
        margin-top: 9px;
      }
      .item-pic {
        font-size: 20px;
        font-weight: bold;
        color: #ff505f;
        span {
          font-size: 14px;
          color: #ff505f;
        }
      }
    }
  }
}
</style>
